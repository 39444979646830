<template>
  <transition v-if="!loading" name="fade">
    <div id="wrapper">
        <sidebar :page="'Manage Admins'" :sidebar_data=sidebar_data></sidebar>
        <div class="body">
            <div class="backdrop" v-on:click="close()"></div>
            
            <admin-header :title="supplier.name" :tagline="supplier.address" v-model:all_screens=all_screens v-model:screen=screen v-model:url=url  :user=user style="margin-bottom: 3rem;"></admin-header>

            <div class="container mb-4">
              <div class="content-wrapper">
                <div class="content mx-auto">
                  <auto-list v-if="url != null" v-model:all_screens=all_screens v-model:screen="screen" :title=title v-model:url="url" v-model:list_data="list_data"></auto-list>
                </div>                                          
              </div>
            </div>
            
            <main-footer></main-footer>
        </div>
        
        <add-user-to-supplier></add-user-to-supplier>

    </div>
  </transition>
</template>

<script>
import MainFooter from '../../components/Footer'
import Sidebar from '../../components/AutoSidebar'
import AdminHeader from '../../components/Header'
import AutoList from '../../components/AutoList'
import AddUserToSupplier from '../../components/Modals/AddUsertoSupplier'

export default {
  name: 'Supplier Stocks',
  components: {
    AdminHeader,
    MainFooter,
    Sidebar,
    AutoList,
    AddUserToSupplier
  },
  data() {
    return {
      url: 'api/suppliers-users/',
      title: {status: true, title: 'Your Data', actions: [
        {name: 'Add Administrator', type: 'modal', action_url: 'add-user-to-supplier'}
      ]},
      all_screens: [
        {title: 'Admins', screen: 1, url: 'api/stocks/supplier/1', actions: [
          {name: 'Remove', type: 'api', action_url: {type: 'put', url:['api/users/', '?supplier_id=0'], message: {success: 'Success!', error: 'Error!'}}},
        ]}
      ],   
      sidebar_data: [
          {title: 'Manage Admins', link: 'suppliers/users', if_children: false, icon: 'users', modal: false},
          {title: 'Supplier Stocks', link: 'suppliers/stocks', if_children: false, icon: 'boxes', modal: false},
          {title: 'Supplier Orders', link: 'suppliers/orders', if_children: false, icon: 'hands-helping', modal: false},
      ],  
      screen: 1,
      list_data: [],    
      supplier: {}        
    }
  },
  watch: {
    user: function() {
      this.ifSupplier()
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    loading () {
      return this.$store.state.loading
    }       
  },    
  mounted() {
    this.$store.dispatch("getUser")
  },
  methods: {
    getSupplier(id) {
      this.axios.get('api/suppliers/' + id).then(response => {
        this.supplier = response.data.data
        console.log(response);
      }).catch(() => {
        this.status_data.errors = true
        this.status_data.error_message = 'Couldn\'t find Supplier details.'
      })
    },
    ifSupplier() {
      if( !this.user.supplier) {
        this.$router.push({name: 'Home'});
      } else {
        this.url = 'api/suppliers-users/' + this.user.supplier_id
        this.getSupplier(this.user.supplier_id)
      }
    }    
  }

}
</script>
