<template>
    <!-- Modal -->
    <div class="modal fade" id="add-user-to-supplier" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Invite user</h5>
                        <p class="text-muted">Invite user to be an administrator.</p>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status>
                            <div class="form-row">
                                <div class="input-group">
                                     <span class="input-group-text border-right-0">
                                        <font-awesome-icon id="eye" :icon="['fa', 'envelope']" />
                                    </span>

                                    <input type="text" class="form-control" placeholder="Enter the user's email" v-model="email" required>
                                </div>
                            </div>

                            <form id="cards" action="#" @submit.prevent="handleSubmit()">
                                <button class="btn bg-orange text-uppercase text-white mt-4 w-100">
                                    <span class="m-0" style="letter-spacing: 1px">Finish</span> <font-awesome-icon class="ms-3" :icon="['fas', 'check']"></font-awesome-icon>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';
    
    .side-menu {
        @include media("<=tablet") {
            display: none;
        }

        p {
            cursor: pointer;
        }
    }

    .top-menu {
        display: none;

        @include media("<=tablet") {
            display: flex;
        }

        p {
            cursor: pointer;
        }
    } 

    #cards {
        display: block!important;

        .card :before {
            background-color: transparent;
        }

        .card {
            cursor: pointer;

            .big-icons {
                font-size: 5rem;

                &:hover > & {
                    color: $orange-color!important;
                }
            }             
        } 
    }    
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'AddUsertoSupplier',
        props: ['modal_data'],
        components: {
            Status
        },
        data() {
            return {
                screen: 0,
                email: null,
                status_data: {
                    "success": false,
                    "success_message": 'Form Submission was successful!',
                    "error": false,
                    "error_message": 'There are some errors.',
                    "loading": false
                },
            }
        },
        methods: {
            handleSubmit() {
                this.status_data.loading = true
                let data = {
                    type: 'supplier',
                    supplier_id: 1,
                    email: this.email
                }

                this.axios.post('api/send-invite', data).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'We\'ve sent the invite! '
                }).catch((error) => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = error.response.data.data
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
        }
    }
</script>